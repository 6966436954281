import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

import { RadioAnswerType, RadioQuestionRes } from '@/api/response.types';
import { computed, ref, watch, nextTick } from 'vue';
import { RadioGroup } from 'ant-design-vue';
import { BaseRadio, BaseTextarea } from '@/components';
import { useTheme } from '@/themes/hooks';
import { useI18n } from 'vue-i18n';

interface Props {
  question: RadioQuestionRes;
}

type ValueType = RadioAnswerType;


export default /*@__PURE__*/_defineComponent({
  __name: 'RadioAnswer',
  props: {
    question: {}
  },
  emits: ["update:value", "update:validation"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

const { t } = useI18n();

const isCustomValue = (value: ValueType) =>
  value !== null && props.question.options.every((opt) => opt.value !== value || opt.custom);

const customOption = computed(() => {
  return props.question.options.find((opt) => opt.custom);
});
const customValueElId = `customValue[${props.question.id}]`;

const innerValue = ref<ValueType>(null);
const innerCustomValue = ref(
  (isCustomValue(props.question.answer) ? props.question.answer : customOption.value?.value) ?? ''
);

const getCustomNormalizeValue = () => {
  return innerCustomValue.value.trim();
};

const onBlueTextarea = () => {
  if (isCustomValue(innerValue.value)) {
    const newValue = getCustomNormalizeValue();

    if (newValue?.length && newValue !== props.question.answer) {
      emit('update:value', newValue);
    }
  }
};

watch(
  () => props.question.answer,
  () => {
    innerValue.value = isCustomValue(props.question.answer) ? customOption.value!.value : props.question.answer;
  },
  { immediate: true }
);
watch(innerValue, (value) => {
  let newValue = value;
  if (isCustomValue(value)) {
    newValue = getCustomNormalizeValue();

    nextTick(() => {
      document.getElementById(customValueElId)?.focus();
    });
  }

  if (newValue) {
    emit('update:value', newValue);
  }
});
watch(
  innerValue,
  (value) => {
    const validateValue = isCustomValue(value) ? getCustomNormalizeValue() : value;
    emit('update:validation', !!validateValue?.length);
  },
  { immediate: true }
);
watch(innerCustomValue, () => {
  emit('update:validation', !!getCustomNormalizeValue().length);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(RadioGroup), {
      value: innerValue.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((innerValue).value = $event)),
      class: _normalizeClass([
      _ctx.$attrs.class,
      'radio-answer',
      { 'has-custom': customOption.value },
      themeStyles.value?.answers?.radioAnswer?.radioAnswer
    ])
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.options, (option) => {
          return (_openBlock(), _createBlock(_unref(BaseRadio), {
            key: option.value,
            value: option.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(option.label), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "class"]),
    (isCustomValue(innerValue.value))
      ? (_openBlock(), _createBlock(_unref(BaseTextarea), {
          key: 0,
          id: customValueElId,
          value: innerCustomValue.value,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((innerCustomValue).value = $event)),
          class: _normalizeClass(['custom-value', themeStyles.value?.answers?.radioAnswer?.customValue]),
          placeholder: _unref(t)('textareaPlaceHolder'),
          onBlur: onBlueTextarea
        }, null, 8, ["value", "class", "placeholder"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})