import { ref, readonly, inject } from 'vue';
import { Theme } from '@/themes/types';
import { themeMap } from '@/themes/consts';
import { THEME_KEY } from '@/consts/common';
import { useFavicon } from '@vueuse/core';

export function useTheme() {
  const theme = inject(THEME_KEY, ref<Theme>());
  const favicon = useFavicon();

  const switchTheme = async (themeKey: string): Promise<Theme> => {
    const themeName = themeMap.get(themeKey);

    switch (themeName) {
      case 'n4': {
        theme.value = (await import('./n4')).theme;
        break;
      }
      case 'dv': {
        theme.value = (await import('./dv')).theme;
        break;
      }
      case 'wz': {
        theme.value = (await import('./wz')).theme;
        break;
      }
      case 'yc': {
        theme.value = (await import('./yc')).theme;
        break;
      }
      case 'ji': {
        theme.value = (await import('./ji')).theme;
        break;
      }
      case '2s': {
        theme.value = (await import('./2s')).theme;
        break;
      }
      case 'kv': {
        theme.value = (await import('./kv')).theme;
        break;
      }
      case 'y7': {
        theme.value = (await import('./y7')).theme;
        break;
      }
    }

    if (!theme.value) {
      theme.value = {};
    }

    if (theme.value.title) {
      document.title = theme.value.title;
    }

    if (theme.value.icons?.favicon) {
      favicon.value = theme.value.icons.favicon;
    }

    return theme.value;
  };

  return {
    theme: readonly(theme),
    switchTheme
  };
}
