import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 4,
  class: "question-item__actions"
}

import { useTheme } from '@/themes/hooks';
import { computed, ref, watch, nextTick } from 'vue';
import { BaseButton, RadioAnswer, CheckAnswer, TextAnswer } from '@/components';
import RightArrow from '@/components/icons/rightArrow.vue';
import { QuestionRes, AnswerType } from '@/api/response.types';
import { useI18n } from 'vue-i18n';

type AnswerValueType = AnswerType;

interface Props {
  questions: QuestionRes[];
  isLoading: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Questions',
  props: {
    questions: {},
    isLoading: { type: Boolean }
  },
  emits: ["update:answer", "send", "progress"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);
const questionStyles = computed(() => themeStyles?.value?.questions?.question);

const { t } = useI18n();

const questionsRef = ref<HTMLDivElement>();
const questionIdx = computed(() => {
  return new Map(props.questions.map((q, i) => [q.id, i + 1]));
});

const visibleQuestions = ref<QuestionRes[]>([]);
const isValid = ref(false);
const validation: Record<string, boolean> = {};

const scrollIntoLastQuestion = () => {
  nextTick(() => {
    questionsRef.value?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  });
};

const emitProgress = () => {
  const value = props.questions.length ? ((visibleQuestions.value.length - 1) / props.questions.length) * 100 : 0;

  emit('progress', value);
};

const addNextVisibleQuestion = () => {
  const nextQuestion = props.questions.find((q) => q.answer == null && !visibleQuestions.value.includes(q));

  if (nextQuestion && !visibleQuestions.value.includes(nextQuestion)) {
    visibleQuestions.value.push(nextQuestion);
    scrollIntoLastQuestion();
    emitProgress();
  }
};

const onUpdateAnswer = (question: QuestionRes, value: AnswerValueType) => {
  emit('update:answer', question, value);
};

const onUpdateValidation = (question: QuestionRes, valid: boolean) => {
  validation[question.id] = valid || !question.required;

  isValid.value = Object.values(validation).every((v) => v);
};

watch(
  () => props.questions,
  () => {
    const oldVisibleQuestionsLength = visibleQuestions.value.length;

    visibleQuestions.value = props.questions.filter((q) => q.answer !== null);

    if (visibleQuestions.value.length < oldVisibleQuestionsLength || visibleQuestions.value.length == 0) {
      addNextVisibleQuestion();
    }
    scrollIntoLastQuestion();

    emitProgress();
  },
  { immediate: true }
);

watch(questionsRef, () => scrollIntoLastQuestion());

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "questionsRef",
    ref: questionsRef,
    class: _normalizeClass(['questions', themeStyles.value?.questions?.questions])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleQuestions.value, (question, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        id: question.id,
        key: question.id,
        class: _normalizeClass(['question-item-wrapper'])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(['question-item', questionStyles.value?.item])
        }, [
          _createElementVNode("span", {
            class: _normalizeClass(['question-item__number', questionStyles.value?.number])
          }, _toDisplayString(_unref(t)('question', { idx: questionIdx.value.get(question.id) })), 3),
          _createElementVNode("span", {
            class: _normalizeClass(['question-item__title', questionStyles.value?.title])
          }, _toDisplayString(question.label), 3),
          (question.description?.length)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(['question-item__description', questionStyles.value?.description])
              }, _toDisplayString(question.description), 3))
            : _createCommentVNode("", true),
          (question.type === 'Radio')
            ? (_openBlock(), _createBlock(_unref(RadioAnswer), {
                key: 1,
                question: question,
                class: _normalizeClass(['question-item__answer', questionStyles.value?.answer]),
                "onUpdate:value": (value) => onUpdateAnswer(question, value),
                "onUpdate:validation": (valid) => onUpdateValidation(question, valid)
              }, null, 8, ["question", "class", "onUpdate:value", "onUpdate:validation"]))
            : (question.type === 'Checkbox')
              ? (_openBlock(), _createBlock(_unref(CheckAnswer), {
                  key: 2,
                  question: question,
                  class: _normalizeClass(['question-item__answer', questionStyles.value?.answer]),
                  "onUpdate:value": (value) => onUpdateAnswer(question, value),
                  "onUpdate:validation": (valid) => onUpdateValidation(question, valid)
                }, null, 8, ["question", "class", "onUpdate:value", "onUpdate:validation"]))
              : (question.type === 'Textarea')
                ? (_openBlock(), _createBlock(_unref(TextAnswer), {
                    key: 3,
                    question: question,
                    class: _normalizeClass(['question-item__answer', questionStyles.value?.answer]),
                    "onUpdate:value": (value) => onUpdateAnswer(question, value),
                    "onUpdate:validation": (valid) => onUpdateValidation(question, valid)
                  }, null, 8, ["question", "class", "onUpdate:value", "onUpdate:validation"]))
                : _createCommentVNode("", true),
          (idx === visibleQuestions.value.length - 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (visibleQuestions.value.length !== _ctx.questions.length)
                  ? (_openBlock(), _createBlock(_unref(BaseButton), {
                      key: 0,
                      disabled: !isValid.value,
                      onClick: addNextVisibleQuestion
                    }, _createSlots({
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('next')) + " ", 1)
                      ]),
                      _: 2
                    }, [
                      (_unref(theme)?.icons?.questions?.next)
                        ? {
                            name: "rightIcon",
                            fn: _withCtx(() => [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(theme).icons.questions.next)))
                            ]),
                            key: "0"
                          }
                        : {
                            name: "rightIcon",
                            fn: _withCtx(() => [
                              _createVNode(RightArrow)
                            ]),
                            key: "1"
                          }
                    ]), 1032, ["disabled"]))
                  : (_openBlock(), _createBlock(_unref(BaseButton), {
                      key: 1,
                      type: "primary",
                      disabled: !isValid.value,
                      class: _normalizeClass(['question-item__actions__send-action']),
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('send')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('send')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"]))
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ], 8, _hoisted_1))
    }), 128))
  ], 2))
}
}

})