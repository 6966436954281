<template>
  <radio :class="['radio', themeStyles?.base?.radio?.radio]">
    <template #default>
      <slot />
    </template>
  </radio>
</template>

<script setup lang="ts">
import { Radio } from 'ant-design-vue';
import { computed } from 'vue';
import { useTheme } from '@/themes/hooks';

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);
</script>

<style scoped lang="less">
.radio {
  font-size: 19px;
  margin-right: 0;
  color: var(--primary-color);

  & :deep(.ant-radio-inner) {
    border: var(--radio-border);
    height: var(--radio-size);
    width: var(--radio-size);
    background: var(--radio-background);
  }

  & :deep(.ant-radio),
  & :deep(.ant-radio-inner) {
    &:after {
      background: var(--radio-checked-color);
      border-color: var(--radio-checked-color);
    }
  }

  & :deep(.ant-radio-input:focus + .ant-radio-inner),
  & :deep(.ant-radio:hover .ant-radio-inner),
  & :deep(.ant-radio-wrapper:hover .ant-radio),
  & :deep(.ant-radio-input:checked + .ant-radio-inner) {
    border-color: var(--radio-hovered-color);
  }
}
</style>
