import { ThemeMap } from '@/themes/types';

export const themeMap: ThemeMap = new Map([
  ['localhost', 'zz'],
  ['survey-n4.pax.websky.tech', 'n4'],
  ['survey-dv.pax.websky.tech', 'dv'],
  ['survey-2s.pax.websky.tech', '2s'],
  ['survey-wz.pax.websky.tech', 'wz'],
  ['survey-yc.pax.websky.tech', 'yc'],
  ['survey-ji.pax.websky.tech', 'ji'],
  ['survey-kv.pax.websky.tech', 'kv'],
  ['survey-y7.pax.websky.tech', 'y7']
]);
