import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Radio } from 'ant-design-vue';
import { computed } from 'vue';
import { useTheme } from '@/themes/hooks';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseRadio',
  setup(__props) {

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Radio), {
    class: _normalizeClass(['radio', themeStyles.value?.base?.radio?.radio])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}
}

})