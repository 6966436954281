import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, isRef as _isRef, withCtx as _withCtx, Transition as _Transition, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  class: /*@__PURE__*/_normalizeClass(['header-content'])
}

import { computed } from 'vue';
import { Layout, LayoutHeader, LayoutContent, Spin, Progress } from 'ant-design-vue';
import { useTheme } from '@/themes/hooks';
import LogoIcon from '@/components/icons/logo.vue';
import QuestionsView from '@/components/questions/Questions.vue';
import QuestionSkeleton from '@/components/questions/Question.skeleton.vue';
import CompleteView from '@/components/Complete.vue';
import ErrorView from '@/components/Error.vue';
import { useSurvey } from '@/hooks/survey';
import LangSelector from '@/components/LangSelector/LangSelector.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyView',
  setup(__props) {

const { theme, switchTheme } = useTheme();
switchTheme(window.location.hostname);
const themeStyles = computed(() => theme.value?.styles);

const {
  surveyModel,
  isLoading,
  error,
  progress,
  isComplete,
  updateAnswer,
  sendComplete,
  allowLanguages,
  selectedLanguage
} = useSurvey();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(Layout), {
      class: _normalizeClass(['root', themeStyles.value?.app?.root])
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Progress), {
          class: "progress",
          percent: _unref(progress),
          "show-info": false
        }, null, 8, ["percent"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_unref(LayoutHeader), {
            class: _normalizeClass(['header', themeStyles.value?.header?.header])
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", {
                  class: _normalizeClass(['header-icon', themeStyles.value?.header?.icon])
                }, [
                  (_unref(theme)?.icons?.logo)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(theme).icons.logo), { key: 0 }))
                    : (_openBlock(), _createBlock(LogoIcon, { key: 1 }))
                ], 2),
                (_unref(selectedLanguage))
                  ? (_openBlock(), _createBlock(LangSelector, {
                      key: 0,
                      "selected-language": _unref(selectedLanguage),
                      "onUpdate:selectedLanguage": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedLanguage) ? (selectedLanguage).value = $event : null)),
                      languages: _unref(allowLanguages)
                    }, null, 8, ["selected-language", "languages"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_unref(LayoutContent), { class: "content" }, {
            default: _withCtx(() => [
              _createVNode(_Transition, {
                name: "fade",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  (_unref(error))
                    ? (_openBlock(), _createBlock(ErrorView, {
                        key: 0,
                        error: _unref(error)
                      }, null, 8, ["error"]))
                    : (_unref(isComplete))
                      ? (_openBlock(), _createBlock(CompleteView, { key: 1 }))
                      : (_unref(surveyModel))
                        ? (_openBlock(), _createBlock(QuestionsView, {
                            key: 2,
                            questions: _unref(surveyModel).questions ?? [],
                            "is-loading": _unref(isLoading),
                            "onUpdate:answer": _unref(updateAnswer),
                            onSend: _unref(sendComplete),
                            onProgress: _cache[1] || (_cache[1] = (value) => (progress.value = value))
                          }, null, 8, ["questions", "is-loading", "onUpdate:answer", "onSend"]))
                        : (_openBlock(), _createBlock(QuestionSkeleton, { key: 3 }))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["class"]),
    (_unref(isLoading) && _unref(surveyModel))
      ? (_openBlock(), _createBlock(_unref(Spin), {
          key: 0,
          class: "loader",
          size: "large",
          spinning: ""
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})