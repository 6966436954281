<template>
  <popover
    :visible="visible"
    overlay-class-name="lang-selector__overlay"
    :get-popup-container="(el) => el.parentElement!"
  >
    <span :class="{ 'lang-selector__selected-item': true, clickable: isClickable }" @click="toggleVisible">
      <img :src="getLangIconUrl(selectedLanguage.locale)" class="lang-selector__item-icon" />
    </span>

    <template #content>
      <div
        v-for="lang in languages"
        :key="lang.locale"
        :class="['lang-selector__item', { selected: lang.locale === selectedLanguage.locale }]"
        @click="() => onItemClick(lang)"
      >
        <img :src="getLangIconUrl(lang.locale)" class="lang-selector__item-icon" />
        {{ lang.label }}
      </div>
    </template>
  </popover>
</template>

<script setup lang="ts">
import { Language } from '@/types';
import { Popover } from 'ant-design-vue';
import { ref, computed } from 'vue';

interface Props {
  selectedLanguage: Language;
  languages: Language[];
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:selectedLanguage', selectedLanguage: Language): void;
}>();

const visible = ref(false);

function onItemClick(language: Language) {
  visible.value = false;
  emit('update:selectedLanguage', language);
}

function getLangIconUrl(locale: string) {
  return `https://cdn.websky.aero/content/frontend/images/locales-flags/${locale}.svg`;
}

const isClickable = computed(() => {
  return props.languages.length > 1;
});

function toggleVisible() {
  if (isClickable.value) {
    visible.value = !visible.value;
  }
}
</script>

<style scoped lang="less">
.lang-selector {
  &__selected-item {
    &.clickable {
      cursor: pointer;
    }
  }

  &__item {
    font-size: 18px;
    font-weight: 500;
    color: var(--primary-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 42px;

    &.selected,
    &:hover {
      color: var(--accent-color);
    }

    &-icon {
      width: 32px;
      height: 24px;
      border-radius: 4px;
    }
  }
}
</style>

<style>
div.ant-popover-inner-content {
  padding: 10px 20px;
}

div.ant-popover-inner {
  border-radius: 4px;
}
</style>
