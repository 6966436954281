import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, VNode } from 'vue';
import { Button as AntButton } from 'ant-design-vue';
import { useTheme } from '@/themes/hooks';
import { ButtonType } from 'ant-design-vue/lib/button';

interface Props {
  leftIcon?: VNode;
  rightIcon?: VNode;
  disabled?: boolean;
  type?: ButtonType;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseButton',
  props: {
    leftIcon: {},
    rightIcon: {},
    disabled: { type: Boolean },
    type: {}
  },
  setup(__props: any) {



const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AntButton), {
    disabled: _ctx.disabled,
    type: _ctx.type,
    class: _normalizeClass(['button', { primary: _ctx.type === 'primary' }, { disabled: _ctx.disabled }, themeStyles.value?.base?.button?.button])
  }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(['button-content', themeStyles.value?.base?.button?.content])
      }, [
        _renderSlot(_ctx.$slots, "default"),
        _renderSlot(_ctx.$slots, "rightIcon")
      ], 2)
    ]),
    _: 2
  }, [
    (_ctx.$slots.leftIcon)
      ? {
          name: "icon",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "leftIcon")
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["disabled", "type", "class"]))
}
}

})