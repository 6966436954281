import { ILogger } from '@/types';
import { App } from 'vue';
import { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';
import { Breadcrumb } from '@sentry/types';

export class SentryLogger implements ILogger {
  private static _instance: SentryLogger;

  private constructor(app: App, router: Router) {
    const dsn = process.env.VUE_APP_SENTRY_DSN?.length
      ? process.env.VUE_APP_SENTRY_DSN
      : 'https://d08cb56eeb3ee56316107a491422e96d@sentry.websky.tech/12';

    Sentry.init({
      app,
      dsn,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
      tracesSampleRate: 1.0,
      environment: process.env.NODE_ENV
    });
  }

  log(message: string, additional?: Omit<Breadcrumb, 'message'>): void {
    Sentry.addBreadcrumb({
      message,
      level: 'info',
      ...additional
    });
  }

  error(message: string): void {
    Sentry.captureException(message);
  }

  static init(app: App, router: Router) {
    if (!SentryLogger._instance) {
      SentryLogger._instance = new SentryLogger(app, router);
    }

    return SentryLogger._instance;
  }
}
