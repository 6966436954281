<template>
  <div class="error">
    <div class="error__content">
      <div class="message">
        <span class="message__title">{{ t(isCompleted ? 'completedTitle' : 'errorTitle', t('errorTitle')) }}</span>
        <span v-if="!isCompleted" class="message__text">{{ t('errorText') }}</span>
        <span v-if="error" class="message__error">{{ t(error) }}</span>
      </div>

      <div :class="['img']">
        <template v-if="theme?.images?.error">
          <component :is="theme.images.error" />
        </template>
        <template v-else>
          <error-img class="img-base" />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useTheme } from '@/themes/hooks';
import ErrorImg from '@/components/images/Error.vue';
import { computed } from 'vue';

const COMPLETED_SURVEY_ERROR_TEXT = 'The survey has already been completed';

interface Props {
  error?: string;
}

const props = defineProps<Props>();

const { t } = useI18n();
const { theme } = useTheme();

const isCompleted = computed(() => props.error === COMPLETED_SURVEY_ERROR_TEXT);
</script>

<style scoped lang="less">
.error {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: var(--question-item-bg-color);
  padding-top: 70px;
  min-height: calc(100vh - var(--progress-bg-width) - var(--header-height));

  &__content {
    display: flex;
    align-items: center;
    max-width: var(--question-item-width-1920);
    margin: 0 auto;
    padding: 0 var(--page-padding);
    width: 100%;
    justify-content: space-between;

    .message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--primary-color);
      font-weight: var(--error-text-font-weight);

      &__title {
        font-size: 59px;
        font-weight: 600;
        line-height: 64.9px;
        letter-spacing: -0.01em;
      }

      &__text {
        font-size: 41px;
        font-weight: 600;
        line-height: 53.3px;
        letter-spacing: -0.01em;
      }

      &__error {
        font-weight: 400;
        font-size: 14px;
        margin-top: 30px;
      }
    }

    .img {
      display: flex;

      &-base {
        color: var(--primary-color);
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "errorTitle": "Sorry,",
    "errorText": "something went wrong",
    "completedTitle": "Survey completed",
    "The survey has already been completed": "Thank you for participating, we appreciate your time and effort."
  },
  "ru": {
    "errorTitle": "Извините,",
    "errorText": "что-то пошло не так",
    "completedTitle": "Опрос завершен",
    "The survey has already been completed": "Спасибо, что приняли участие, мы ценим ваше время и усилия."
  },
  "hy": {
    "errorTitle": "ներեցեք,",
    "errorText": "ինչ-որ բան այնպես չէ",
    "completedTitle": "Հարցումն ավարտված է",
    "The survey has already been completed": "Շնորհակալություն մասնակցության համար, մենք գնահատում ենք ձեր ժամանակն ու ջանքերը."
  },
  "kk": {
    "errorTitle": "Кешіріңіз,",
    "errorText": "бір қателік кетті",
    "completedTitle": "Сауалнама аяқталды",
    "The survey has already been completed": "Қатысқаныңыз үшін рахмет, уақытыңыз бен күш-жігеріңізді бағалаймыз."
  }
}
</i18n>
