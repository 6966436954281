import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

import { CheckAnswerType, CheckQuestionRes, QuestionOption } from '@/api/response.types';
import { computed, nextTick, ref, watch } from 'vue';
import { BaseCheckbox, BaseTextarea } from '@/components';
import { useTheme } from '@/themes/hooks';
import { useI18n } from 'vue-i18n';

interface Props {
  question: CheckQuestionRes;
}

type ValueType = CheckAnswerType;


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckAnswer',
  props: {
    question: {}
  },
  emits: ["update:value", "update:validation"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);

const { t } = useI18n();

const customValueElId = `customValue[${props.question.id}]`;
const staticOptions = computed(() => props.question.options.filter((opt) => !opt.custom));
const customOption = computed(() => {
  return props.question.options?.find((opt) => opt.custom);
});

const getCustomValue = (value: string[] | null) => {
  return value?.find((i) => staticOptions.value.every((opt) => opt.value !== i)) ?? customOption.value?.value ?? '';
};

const innerValue = ref<ValueType>(null);
const innerCustomValue = ref(getCustomValue(props.question.answer));

const getCustomNormalizeValue = () => {
  return innerCustomValue.value.trim();
};

const optionChecked = (option: QuestionOption) => {
  if (!option) {
    return false;
  }
  if (option.custom) {
    return innerValue.value?.some((i) => staticOptions.value.every((opt) => opt.value !== i));
  }
  return innerValue.value?.includes(option.value);
};

const onUpdateChecked = (option: QuestionOption, checked: boolean) => {
  let newQuestionValue: ValueType;
  if (checked) {
    newQuestionValue = innerValue.value ? [...innerValue.value] : [];
    newQuestionValue.push(option.custom ? getCustomNormalizeValue() : option.value);
  } else {
    const targetValue = option.custom ? getCustomNormalizeValue() : option.value;
    newQuestionValue = innerValue.value!.filter((i) => i !== targetValue);
  }

  if (option.custom) {
    nextTick(() => {
      document.getElementById(customValueElId)?.focus();
    });
  }

  if (newQuestionValue.length && (!option.custom || innerCustomValue.value.length)) {
    emit('update:value', newQuestionValue);
  }

  if (option.custom) {
    emit('update:validation', !!innerCustomValue.value.length);
  }

  innerValue.value = newQuestionValue;
};

const onBlueTextarea = () => {
  const customNormalizeValue = getCustomNormalizeValue();
  if (
    customNormalizeValue.length &&
    customNormalizeValue !== getCustomValue(props.question.answer) &&
    innerValue.value?.length
  ) {
    const notCustomOptionValues: Set<string> = new Set(
      props.question.options.filter((opt) => !opt.custom).map((opt) => opt.value)
    );
    const newValue = innerValue.value?.map((v) => (notCustomOptionValues.has(v) ? v : customNormalizeValue)) ?? [
      customNormalizeValue
    ];

    emit('update:value', newValue);
  }
};

watch(
  () => props.question.answer,
  () => {
    innerValue.value = props.question.answer;
  },
  { immediate: true }
);
watch(
  innerValue,
  (value) => {
    const isValid = !!value?.length && value.every((v) => v.length);
    emit('update:validation', isValid);
  },
  { immediate: true }
);
watch(innerCustomValue, (newValue, oldValue) => {
  innerValue.value = innerValue.value?.map((v) => (v === oldValue ? getCustomNormalizeValue() : v)) ?? null;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['check-answer', themeStyles.value?.answers?.checkAnswer?.checkAnswer])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.options, (option) => {
      return (_openBlock(), _createBlock(_unref(BaseCheckbox), {
        key: option.value,
        checked: optionChecked(option),
        "onUpdate:checked": (value) => onUpdateChecked(option, value)
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(option.label), 1)
        ]),
        _: 2
      }, 1032, ["checked", "onUpdate:checked"]))
    }), 128)),
    (optionChecked(customOption.value))
      ? (_openBlock(), _createBlock(_unref(BaseTextarea), {
          key: 0,
          id: customValueElId,
          value: innerCustomValue.value,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((innerCustomValue).value = $event)),
          class: _normalizeClass(['custom-value', themeStyles.value?.answers?.checkAnswer?.customValue]),
          placeholder: _unref(t)('textareaPlaceHolder'),
          onBlur: onBlueTextarea
        }, null, 8, ["value", "class", "placeholder"]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})