export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry,"])},
        "errorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["something went wrong"])},
        "completedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Survey completed"])},
        "The survey has already been completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for participating, we appreciate your time and effort."])}
      },
      "ru": {
        "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извините,"])},
        "errorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["что-то пошло не так"])},
        "completedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опрос завершен"])},
        "The survey has already been completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо, что приняли участие, мы ценим ваше время и усилия."])}
      },
      "hy": {
        "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ներեցեք,"])},
        "errorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ինչ-որ բան այնպես չէ"])},
        "completedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Հարցումն ավարտված է"])},
        "The survey has already been completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շնորհակալություն մասնակցության համար, մենք գնահատում ենք ձեր ժամանակն ու ջանքերը."])}
      },
      "kk": {
        "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кешіріңіз,"])},
        "errorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бір қателік кетті"])},
        "completedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сауалнама аяқталды"])},
        "The survey has already been completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қатысқаныңыз үшін рахмет, уақытыңыз бен күш-жігеріңізді бағалаймыз."])}
      }
    }
  })
}
