import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(['complete'])
}
const _hoisted_2 = {
  class: /*@__PURE__*/_normalizeClass(['complete__content'])
}
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass(['complete__content__header'])
}
const _hoisted_4 = {
  class: /*@__PURE__*/_normalizeClass(['complete__content__header__title'])
}
const _hoisted_5 = {
  class: /*@__PURE__*/_normalizeClass(['complete__content__header__description'])
}
const _hoisted_6 = {
  class: /*@__PURE__*/_normalizeClass(['complete__content__img'])
}

import CompleteImg from '@/components/images/Complete.vue';
import { useTheme } from '@/themes/hooks';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'Complete',
  setup(__props) {

const { theme } = useTheme();
const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)(`${_unref(theme)?.translate?.complete?.['Thank you!'] ?? 'Thank you!'}`)), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(t)(`${_unref(theme)?.translate?.complete?.['You helped us a lot'] ?? 'You helped us a lot'}`)), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_unref(theme)?.images?.complete)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(theme).images.complete), { key: 0 }))
          : (_openBlock(), _createBlock(CompleteImg, { key: 1 }))
      ])
    ])
  ]))
}
}

})