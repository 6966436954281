import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ErrorView from '@/components/Error.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'NotFoundView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ErrorView, { class: "not-found" }))
}
}

})