<template>
  <router-view />
</template>

<script setup lang="ts">
import { computed, provide, ref } from 'vue';
import { THEME_KEY } from '@/consts/common';
import { Theme } from '@/themes/types';
import { useHead } from '@vueuse/head';
import { useI18n } from 'vue-i18n';

provide(THEME_KEY, ref<Theme>());

const { t } = useI18n();

useHead({
  title: computed(() => t('Survey'))
});
</script>

<i18n>
{
  "en": {
    "Survey": "Survey"
  },
  "ru": {
    "Survey": "Опрос"
  }
}
</i18n>
