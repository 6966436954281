import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

import { Language } from '@/types';
import { Popover } from 'ant-design-vue';
import { ref, computed } from 'vue';

interface Props {
  selectedLanguage: Language;
  languages: Language[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LangSelector',
  props: {
    selectedLanguage: {},
    languages: {}
  },
  emits: ["update:selectedLanguage"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const visible = ref(false);

function onItemClick(language: Language) {
  visible.value = false;
  emit('update:selectedLanguage', language);
}

function getLangIconUrl(locale: string) {
  return `https://cdn.websky.aero/content/frontend/images/locales-flags/${locale}.svg`;
}

const isClickable = computed(() => {
  return props.languages.length > 1;
});

function toggleVisible() {
  if (isClickable.value) {
    visible.value = !visible.value;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Popover), {
    visible: visible.value,
    "overlay-class-name": "lang-selector__overlay",
    "get-popup-container": (el) => el.parentElement!
  }, {
    content: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (lang) => {
        return (_openBlock(), _createElementBlock("div", {
          key: lang.locale,
          class: _normalizeClass(['lang-selector__item', { selected: lang.locale === _ctx.selectedLanguage.locale }]),
          onClick: () => onItemClick(lang)
        }, [
          _createElementVNode("img", {
            src: getLangIconUrl(lang.locale),
            class: "lang-selector__item-icon"
          }, null, 8, _hoisted_3),
          _createTextVNode(" " + _toDisplayString(lang.label), 1)
        ], 10, _hoisted_2))
      }), 128))
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass({ 'lang-selector__selected-item': true, clickable: isClickable.value }),
        onClick: toggleVisible
      }, [
        _createElementVNode("img", {
          src: getLangIconUrl(_ctx.selectedLanguage.locale),
          class: "lang-selector__item-icon"
        }, null, 8, _hoisted_1)
      ], 2)
    ]),
    _: 1
  }, 8, ["visible", "get-popup-container"]))
}
}

})