export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
        "You helped us a lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You helped us a lot"])},
        "Thank you for participating in the survey!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for participating in the survey!"])},
        "Your feedback is very important to us.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your feedback is very important to us."])},
        "Thanks for the answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for the answers!"])},
        "We are changing for the better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to your opinion, we are changing for the better."])},
        "Thanks for your participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your participation!"])},
        "Your opinion is very important to us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your opinion is very important to us!"])}
      },
      "ru": {
        "Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо!"])},
        "You helped us a lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы нам очень помогли"])},
        "Thank you for participating in the survey!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарим за участие в опросе!"])},
        "Your feedback is very important to us.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше мнение очень важно для нас."])},
        "Thanks for the answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо за ответы"])},
        "We are changing for the better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодаря Вашему мнению мы меняемся к лучшему"])},
        "Thanks for your participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо за участие!"])},
        "Your opinion is very important to us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше мнение очень важно для нас!"])}
      },
      "hy": {
        "Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շնորհակալություն"])},
        "You helped us a lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Դուք մեզ շատ օգնեցիք"])},
        "Thanks for the answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Շնորհակալություն պատասխանների համար։"])},
        "We are changing for the better": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ձեր կարծիքի շնորհիվ մենք փոխվում ենք դեպի լավը"])}
      },
      "kk": {
        "Thank you!": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рақмет"])},
        "You helped us a lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сіздің үлкен көмегіңіз тиді"])}
      }
    }
  })
}
