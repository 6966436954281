export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Question ", _interpolate(_named("idx"))])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])}
      },
      "ru": {
        "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вопрос ", _interpolate(_named("idx"))])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])}
      },
      "hy": {
        "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["հարց ", _interpolate(_named("idx"))])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["հաջորդը"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ուղարկել"])}
      },
      "kk": {
        "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сұрақ ", _interpolate(_named("idx"))])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Әрі қарай"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіберу"])}
      }
    }
  })
}
