<template>
  <checkbox :class="['checkbox', themeStyles?.base?.checkbox?.checkbox]">
    <template #default>
      <slot />
    </template>
  </checkbox>
</template>

<script setup lang="ts">
import { Checkbox } from 'ant-design-vue';
import { computed } from 'vue';
import { useTheme } from '@/themes/hooks';

interface Props {}
defineProps<Props>();

const { theme } = useTheme();
const themeStyles = computed(() => theme.value?.styles);
</script>

<style scoped lang="less">
.checkbox {
  font-size: 19px;
  color: var(--primary-color);

  &.ant-checkbox-wrapper {
    margin-left: 0;
  }

  & :deep(.ant-checkbox-inner) {
    border: var(--checkbox-border);
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    background: var(--checkbox-background);
    border-radius: var(--checkbox-border-radius);
  }

  & :deep(.ant-checkbox-input:focus + .ant-checkbox-inner) {
    border: var(--checkbox-border);
  }

  & :deep(.ant-checkbox-checked::after),
  & :deep(.ant-checkbox-checked .ant-checkbox-inner::after) {
    border-color: var(--checkbox-color);
  }

  &.ant-checkbox-wrapper:hover :deep(.ant-checkbox-inner),
  & :deep(.ant-checkbox:hover .ant-checkbox-inner),
  & :deep(.ant-checkbox-input:checked + .ant-checkbox-inner) {
    border-color: var(--checkbox-hovered-color);
  }
}
</style>
